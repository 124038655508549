$(document).ready(function () {
    const $html = $('html');
    const $body = $('body');
    const $header = $('[data-plugin="sticky"]');
    const $h_height = $('#top').innerHeight();
    
    //Hamburger
    let $button_close = $('[data-bs-toggle="collapse"].hamburger');

    if ($button_close.length > 0) {
        $button_close.on('click', function () {
            let $this = $(this);

            if (!$this.hasClass('is-active')) {
                $this.addClass('is-active');

                if ($(window).width() < 1200) {
                    $body.addClass('menu-open');
                    $html.addClass('menu-open');
                }
            } else {
                $this.removeClass('is-active');
                $body.removeClass('menu-open');
                $html.removeClass('menu-open');
            }
        });
    }

    //Sticky on mouse scroll
    if ($header.length > 0) {
        let position = $body.offset().top + $h_height;

        if (window.pageYOffset >= position) {
            $header.addClass('sticky');
            $html.addClass('sticky');
        }

        $(window).scroll(function () {
            if ($(document).scrollTop() > position) {
                $header.addClass('sticky');
                $html.addClass('sticky');
            } else {
                $header.removeClass('sticky');
                $html.removeClass('sticky');
            }
        });
    }
});