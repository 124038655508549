window.$ = window.jQuery = require('jquery');

require('./components/Menu');
require('./components/AnimateNumbers');
require('./components/Parallax');

//Bootstrap import
require('@popperjs/core');
require('bootstrap/js/src/button');
require('bootstrap/js/src/collapse');
require('bootstrap/js/src/dropdown');
require('bootstrap/js/src/tab');

window.WOW = require('wow.js');

$(function () {
    setTimeout(() => {
        new window.WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            mobile: true,
            live: true,
            offset: -200
        }).init();
    }, 600);
})